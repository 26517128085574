import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { of as observableOf } from 'rxjs/observable/of'
import 'rxjs/add/operator/map';

import { HttpClient,HttpErrorResponse} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { baseUrlMarket } from '../constants';
import { TimerPlayer } from './models/timerPlayer';



@Injectable() 
export class TimerService {
    private _timerPlayer: BehaviorSubject<TimerPlayer>; 
    
    // this object is observed in the countdown component
    timerPlayer: Observable<TimerPlayer> = observableOf()

    private baseUrl: string;
    private dataStore: {  // This is where we will store our data in memory
      timerPlayer :TimerPlayer
    };
      
  
    constructor(private httpService: HttpClient, private http: Http) {
        this.baseUrl  = baseUrlMarket;
        let _initialValue = {timer: undefined, player: undefined };
        this.dataStore = { timerPlayer: _initialValue};
        this._timerPlayer= <BehaviorSubject<TimerPlayer>>new BehaviorSubject(_initialValue);
        this.timerPlayer = this._timerPlayer.asObservable();
    }


    /**
     * GET the timer value from the DB.
     * @param seconds 
     * 
     */
    getTimerValue(){
        this.httpService.get( this.baseUrl + 'getTimerPlayer.php',  {}  ).subscribe(data => {
            let result = data as TimerPlayer;
            this.dataStore.timerPlayer = {timer: result["value"], player: result["player"]};
            this._timerPlayer.next(Object.assign({}, this.dataStore.timerPlayer));      
          }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured.");
            } else {
              console.log("Server-side error occured: " + err.message);
            }
          })
    }

    /**
     * POST the timer value and store in the DB.
     * Only the leader can set the timer value.
     * @param seconds 
     * 
     */
    setTimerValue(seconds:number, player:string){
        const httpOptions = {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            }
        };

        this.httpService.post(this.baseUrl + "insertTimerPlayer.php", {
        "value": seconds, "player": player }, httpOptions).subscribe(data => {
                let result = data as string;
                if (result["result"] == "OK"){
                    console.log("timer and player updated")
                }
                else{
                    alert("ERROR OCCURRED with the timer")
                }
        }, error => console.log('Could not update the timer.'));

    }
}
