import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { HttpClient,  HttpErrorResponse , HttpHeaders, HttpParams} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { baseUrlMarket, LEGA } from '../constants';

import { President } from './models/president';
import { Market } from './models/market';
// import { TeamsTableComponent } from './teams-table/teams-table.component';



export interface Offer {
    user: string;
    value: string;
}


@Injectable() 
export class UserService {

    lega: string = LEGA

    private _users: BehaviorSubject<string[]>; 
    private _offers: BehaviorSubject<Offer[]>;
    private _presidents: BehaviorSubject<President[]>;
    private _marketEntries: BehaviorSubject<Market[]>;
    private _historyPlayer: BehaviorSubject<Market[]>;

    users: Observable<string[]>
    offers: Observable<Offer[]>
    marketEntries: Observable<Market[]>
    historyPlayer: Observable<Market[]>
    presidents: Observable<President[]>

    last_offer:Observable<number>
    private baseUrl: string;
    
    dataStore: {  // This is where we will store our data in memory
      users: string[]
      offers: Offer[]
      last_offer:number
      presidents: President[]
      marketEntries: Market[]
      historyPlayer: Market[]
    };
      
  
    constructor(private httpService: HttpClient, private http: Http) {
      this.baseUrl  = baseUrlMarket;
      
      this.dataStore = { users: [], offers: [], last_offer: 0, presidents: [], marketEntries: [], historyPlayer: []};
      
      this._users= <BehaviorSubject<string[]>>new BehaviorSubject([]);
      this._offers= <BehaviorSubject<Offer[]>>new BehaviorSubject([]);
      this._presidents= <BehaviorSubject<President[]>>new BehaviorSubject([]);
      this._marketEntries= <BehaviorSubject<Market[]>>new BehaviorSubject([]);
      this._historyPlayer= <BehaviorSubject<Market[]>>new BehaviorSubject([]);

      this.users = this._users.asObservable();
      this.offers = this._offers.asObservable();
      this.presidents = this._presidents.asObservable();
      this.marketEntries = this._marketEntries.asObservable();
      this.historyPlayer = this._historyPlayer.asObservable();
    }
    
    loadUsers() {
      this.httpService.get( this.baseUrl + 'getUsers.php',  { }  ).subscribe(data => {
        this.dataStore.users = data as string[];
        this._users.next(Object.assign({}, this.dataStore).users);
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured: " + err.message);
        }
      })
    }

    create(userName: string) {
        const httpOptions = {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            }
        };
        this.httpService.post(this.baseUrl + "insertUser.php", {
        "name":  userName }, httpOptions).subscribe(data => {
                let result = data as string;
                if (result["result"] == "OK"){
                    this.dataStore.users.push(userName);
                    this._users.next(Object.assign({}, this.dataStore).users);
                }
                else{
                    if (result["result"] == "Already present"){
                        console.log("user already present");
                    }
                }
        }, error => console.log('Could not create a user.'));
    }

    /**
     * Get the user details from the username
     */
    getPresidents() {
      this.httpService.get( this.baseUrl + 'getPresidents.php',  { }  ).subscribe(data => {
        this.dataStore.presidents = data as President[];        
        this._presidents.next(Object.assign({}, this.dataStore).presidents);
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured: " + err.message);
        }
      })
    }

    updatePresident(president: President) {
      const httpOptions = {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            }
      };

      this.httpService.post(this.baseUrl + "updatePresident.php", 
        { "username": president["username"], "current": president["current"], "positionInAuction": president["positionInAuction"] }, httpOptions).subscribe(data => {
            let result = data as string;
            if (result["result"] == "OK"){
                console.log("president updated")
            }
            else{
                alert("ERROR OCCURRED: president status not updated! Try again!")
            }
        }, error => {
            console.log('Could not submit the offer!: ', error.message);
            alert("ERROR OCCURRED: offer not submitted! Try again!")
        });
    }




    remove(userName: string) {
        const httpOptions = {
            headers : {'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'}
        };
        this.httpService.post(this.baseUrl + "deleteUser.php", {"name":  userName }, httpOptions).subscribe(data => {

          this.dataStore.users.forEach((t, i) => {
            if (t === userName){
                this.dataStore.users.splice(i, 1); 
            }
          });
    
          this._users.next(Object.assign({}, this.dataStore).users);
        }, error => console.log('Could not delete user.'));
    }

    /**
     * Submit the offer to the DB
     * @param user president that makes the offer
     * @param player player to aquire
     * @param offer actual offer
     */
    makeOffer(user: string, player:string, offer:number) {
        const httpOptions = {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            }
        };

        //console.log(user, player, offer)
        this.httpService.post(this.baseUrl + "insertOffer.php", 
        { "player": player, "user": user, "offer": offer }, httpOptions).subscribe(data => {
                
            let result = data as string;
            //console.log(result);
            if (result["result"] == "OK"){
                console.log("offer submitted")
            }
            else{
                alert("ERROR OCCURRED: offer not submitted! Try again!")
            }
        }, error => {
            console.log('Could not submit the offer!: ', error.message);
            alert("ERROR OCCURRED: offer not submitted! Try again!")
        });
    }

    /**
     * Get the offers for the single player to decide who acquired the player.
     */
    getOffers() {

        this.httpService.get( this.baseUrl + 'getOffers.php', { }  ).subscribe(data => {
          this.dataStore.offers = data as Offer[];
          this._offers.next(Object.assign({}, this.dataStore).offers);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured: " + err.message);
          }
        })
    }



    /**
     * Get the user details from the username
     */
    addPlayerToUser(username: string, player: string, role: string, cost: number, baseCost: number, fantaId?: string) {
        const httpOptions = {
          headers : {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
          }
      };

      player = player.replace('\'', '')
      console.log(player)

      this.httpService.post(this.baseUrl + 'addPlayerToUser.php', 
      { "username": username, "player": player, "role": role, "cost": cost, "baseCost": baseCost, "lega": this.lega, "fantaId": fantaId}, httpOptions).subscribe(data => {
          let result = data as string;
          if (result["result"] == "OK"){
              console.log("player added to market")
              this.getAllMarketEntries(new HttpParams().set("lega", this.lega))
          }
          else{
              alert("ERROR OCCURRED: player not added! Try again!")
          }
      }, error => {
          console.log('Could not submit the offer!: ', error.message);
          alert("ERROR OCCURRED: offer not submitted! Try again!")
      });
    }

    /**
     * Get all teams for each user in order to display the current lineaups and the remaining money
     */
    getAllMarketEntries(httpParams: HttpParams) {

      this.httpService.get( this.baseUrl + 'getMarketEntries.php',  { params: httpParams }  ).subscribe(data => {
        this.dataStore.marketEntries = data as Market[];        
        this._marketEntries.next(Object.assign({}, this.dataStore).marketEntries);
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured: " + err.message);
        }
      })
    }

    getHistoryPlayer(httpParams: HttpParams) {
      this.httpService.get( this.baseUrl + 'getMarketEntries.php',  { params: httpParams }  ).subscribe(data => {
        this.dataStore.historyPlayer = data as Market[];        
        this._historyPlayer.next(Object.assign({}, this.dataStore).historyPlayer);
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured: " + err.message);
        }
      })
    }
  }
