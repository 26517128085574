import { Component, Inject } from '@angular/core';

import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';


import { LANGUAGES, NUMBER_ROOMS } from './constants';
import { Language }  from './domain/language.model';
import { Observable } from 'rxjs/Observable';

import { WindowRef} from './WindowRef';

import { LOCALE_ID } from '@angular/core';
import { text } from './text'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './home/home.component.css' ]
})
export class AppComponent {

  language:Language = {id:LANGUAGES.indexOf('it'),value:'it'};
  language$:Observable<Language>;
  number_rooms:number = NUMBER_ROOMS;

  currentRoute: string = ""
  
  private fragment: string;

  constructor(private route: ActivatedRoute, private router: Router, private winRef: WindowRef, @Inject(LOCALE_ID) locale: string){

    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationError) {
          // Present error to user
          console.log(event.error);
      }

      var routerArray = this.router.url.split("/")
      this.currentRoute = routerArray[routerArray.length-1]
    });

  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  changeLanguage(newLanguage: string) {
    this.router.navigate([newLanguage]);
    this.language = {id:LANGUAGES.indexOf(newLanguage),value:newLanguage};
  }

  
  activateEvent(event) {
    console.log('Activate Event:', event);
  }
  
  deactivateEvent(event) {
    console.log('Deactivate Event', event);
  }
 
  navigate(lang:string, dest:string){
    this.router.navigate( [lang], {fragment: dest});

    var element = document.getElementById(dest);
    element.scrollIntoView({behavior: "smooth", block: "start"});
  }

  getLabel(label: string, language: string) {
    return text[label+'Label_'+language]
  }

}
