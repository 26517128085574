import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';

import { MatSidenavModule, MatToolbarModule, MatIconModule, MatListModule, MatCardModule} from '@angular/material/';


//import { EffectsModule } from '@ngrx/effects';
//import { RouterStoreModule } from '@ngrx/router-store';
//import { useLogMonitor } from '@ngrx/store-log-monitor';
import { routes } from './app.routing';


export const APP_IMPORTS = [
  ReactiveFormsModule,
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  //RouterStoreModule.connectRouter()
  ];
  
