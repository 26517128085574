
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { text } from '../text';

@Component({
  selector: 'my-where',
  templateUrl: './where.component.html',
  styleUrls: ['where.component.css'],

})

export class WhereComponent{
    @Input() language: string


    constructor(private route: ActivatedRoute, private router: Router){}

    getLabel(label: string, language: string) {
        return text[label+'Label_'+language]
    }

    getDescription(label: string, language: string) {
        return text[label+'Description_'+language]
    }

}