import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NUMBER_PHOTOS_CATONE29, NUMBER_PHOTOS_GIULIO_CESARE } from '../constants';
import { text } from '../text'

class Apartment {
    name:string;
    nPeople:number;
    level:string;
    position:string;
    configuration:string;
    description:string
    photos:string[]

    constructor(name:string, nPeople:number, level:string, position:string, configuration:string,
        description:string, photos:string[]){
        this.name = name;  
        this.nPeople = nPeople;
        this.level = level;
        this.position = position;
        this.configuration = configuration;
        this.description = description
        this.photos = photos
    }
}


@Component({
  selector: 'my-apartments',
  templateUrl: './apartments.component.html',
  styleUrls: ['apartments.component.css'],

})
export class ApartmentsComponent{
    
    @Input() language: string
    
    apartments:Apartment[];
    
    path_photos_catone29:string = '/assets/themes/dr/images/apartmentCatone29/';
    photo_catone29:string[] = [];

    path_photos_giulio_cesare:string = '/assets/themes/dr/images/apartmentGiulioCesare/';
    photo_giulio_cesare:string[] = [];


    constructor(private route: ActivatedRoute, private router: Router){
        
        for(let i=0; i<NUMBER_PHOTOS_CATONE29; i++){
            this.photo_catone29.push(this.path_photos_catone29 + i + ".jpg")
        }

        for(let i=0; i<NUMBER_PHOTOS_GIULIO_CESARE; i++){
            this.photo_giulio_cesare.push(this.path_photos_giulio_cesare + i + ".jpg")
        }
    }

    ngOnInit(): void {
        this.apartments = [
            {name: "catone29", nPeople:13, level:"Luxury", position:"via Catone 29", configuration: this.getConfigurationDescription(4,4,1),
            description:"",
            photos: this.photo_catone29},
            
            {name: "giuglioCesare128", nPeople:7, level:"Standard", position:"viale Giulio Cesare 128", configuration:this.getConfigurationDescription(3,3,1),
            description: "",
            photos: this.photo_giulio_cesare}
        ]
    }

    getConfigurationDescription(rooms: number, bathrooms: number, kitchens: number) {
        return rooms + ' ' + text['roomsLabel_'+this.language] + ', '+ bathrooms + ' ' + text['bathroomsLabel_'+this.language] + ', '+ kitchens + ' ' + text['kitchensLabel_'+this.language] 
    }

    getLabel(label: string, language: string) {
        return text[label+'Label_'+language]
    }

    getDescription(label: string, language: string) {
        return text[label+'Description_'+language]
    }

}