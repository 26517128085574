import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { MarketComponent } from './market-session/market.component';


export const routes: Routes = [
  { path: '', component: AppComponent },
  { path: ':lang', component: AppComponent, pathMatch: 'full' },
  { path: ':lang/fantamarket', component: MarketComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
