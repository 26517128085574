import {NUMBER_ROOMS, MAX_NUMER_PEOPLE_APARTMENTS} from "./constants"

export const text = {

    // Italian text
    "ourofferLabel_it": "La nostra offerta",
    "aboutLabel_it": "About",
    "roomsLabel_it": "Camere",
    "apartmentsLabel_it": "Appartamenti",
    "whereLabel_it": "Dove siamo",
    "contactsLabel_it": "Contatti",
    "peopleLabel_it": "Persone",
    "configurationLabel_it": "Configurazione",
    "bathroomsLabel_it": "Bagni",
    "kitchensLabel_it": "Cucine",
    "booknowLabel_it": "Prenota ora!",

    "unique_locationLabel_it": "Posizione unica",
    "receptionLabel_it": "Reception 9:00-24:00",
    "airportLabel_it": "Aeroporto a €6,00",
    "easy_by_carLabel_it": "Semplice in auto",
    "discountLabel_it": "Sconto 15% se già cliente",
    "free_brochureLabel_it": "Brochure gratuita",


    "unique_locationDescription_it": `
        <p>A soli 300 mt dalla basilica di S.Pietro e 150 mt dalla metro Ottaviano</p>
        <p>A piedi a Piazza Navona, Fontana di Trevi e Trastevere</p>
    `,
    "receptionDescription_it": `
        <p>Check-in Via Catone 21 e in via Leone IV 38. dopo le 24 con supplemento.</p>
        <p>Check-out: entro le 10.30</p>
    `,
    "airportDescription_it": `
        <p> Bus ogni 20 min </p>
        <p> Fiumicino - via Crescenzio </p>
        <p> Ciampino - Termini </p>
        <p> Su richiesta, possibilità di pick up privato.</p>        
    `,
    "easy_by_carDescription_it": `
        <p>Non ci sono limiti all'accesso all'area. Parcheggio in strada, zona sicura, o Parking Prati a 100 mt.</p>  
    `,
    "discountDescription_it": `
        <p>Sconto riservato anche ai tuoi familiari ed amici.</p>
    `,
    "free_brochureDescription_it": `
        <p>Ti aiutiamo con ristoranti, noleggio auto, scooter e bici.</p>
    `,


    "roomsDescription_it": `${NUMBER_ROOMS} camere nelle varie tipologie. Tutte in zona Vaticano/Prati. 
    Le camere sono luminose, con bagno interno, WI-FI gratuito all'interno di ogni camera, aria condizionata, mini frigo, asciugacapelli, TV LCD. Pulizie giornaliere e cambio biancheria.\
    `,

    "apartmentsDescription_it": `Per chi è in famiglia o in gruppo e desidera un soggiorno confortevole in posizioni ottime e ben collegate, mantenendo la privacy che solo un appartamento può offrire. Sono disponibili appartamenti di varie tipologie per accogliere fino a 13 persone. Ogni appartamento è dotato di WI-FI gratuito, tutti i servizi, ed uso cucina.`,

    "contactsDescription_it": `
        <div>
            Ricordiamo i vantaggi se scegli di prenotare tramite il nostro sito o via mail:
            <ol>
                <li>colazione gratuita</li>
                <li>deposito bagagli gratuito prima del check-in e dopo il check-out</li>
                <li>upgrade della camera, in base alla disponibilità</li>
            </ol>
        </div>

        <div>
            Per ulteriori informazioni potete:
            <ul>
                <li>inviare un'email a info@whitevatican.com</li>
                <li>telefonare ai numeri (+39) 3426425792 oppure (+39) 335 7804080</li>
                <li>Fax (+39) 06 93380492</li>
            </ul>
        </div>

        <p>
            Per le prenotazioni dirette via email potete scrivere a info@whitevatican.com
        </p>
        <p>Un anticipo attraverso un bonifico bancario oppure il numero della carta di credito garantiranno la prenotazione.</p>
        <p>La cancellazione della prenotazione è gratuita se effettuata con preavviso minimo di 7gg, altrimenti verrà applicata la penale pari al 30%.</p>

        <h3>RECEPTION C/O WHITE VATICAN</h3>
        <h4>Scala sinistra, 3° piano, interno 8</h4>
    `,

    "reminder_booknowDescription_it": `
        Se prenoti qui:
        <ul>
            <li>colazione gratuita</li>
            <li>deposito bagagli gratuito</li>
            <li>upgrade della camera, in base alla disponibilità</li>
        </ul>
    `,

    
    // English text
    "ourofferLabel_en": "Our offer",
    "aboutLabel_en": "",
    "roomsLabel_en": "Rooms",
    "apartmentsLabel_en": "Apartments",
    "whereLabel_en": "Where are we",
    "contactsLabel_en": "Contacts",
    "peopleLabel_en": "People",
    "configurationLabel_en": "Configuration",
    "bathroomsLabel_en": "Bathrooms",
    "kitchensLabel_en": "Kitchens",
    "booknowLabel_en": "Book now!",


    "unique_locationLabel_en": "Unique location",
    "receptionLabel_en": "Reception 9:00-24:00",
    "airportLabel_en": "Airport with €6,00",
    "easy_by_carLabel_en": "Easy by car",
    "discountLabel_en": "15% off for already customers",
    "free_brochureLabel_en": "Free brochure",


    "unique_locationDescription_en": `
        <p>300 mt from S.Peter church and 150 mt from undergorund Ottaviano</p>
        <p>Very close to Piazza Navona, Fontana di Trevi and Trastevere</p>
    `,
    "receptionDescription_en": `
        <p>Check-in Via Catone 21 and in via Leone IV 38. Extra charge after 24:00.</p>
        <p>Check-out: not later than 10.30</p>
    `,
    "airportDescription_en": `
        <p> Bus every 20 min </p>
        <p> Fiumicino airport - via Crescenzio </p>
        <p> Ciampino airport - Termini </p>
        <p> On demand, private pickup.</p>        
    `,
    "easy_by_carDescription_en": `
        <p>No access limit by car. Safe parking in street or Parking Prati.</p>  
    `,
    "discountDescription_en": `
        <p>Discount to friends and relatives too.</p>
    `,
    "free_brochureDescription_en": `
        <p>We help you with restaurants, car, motorcycle or bicycle rental.</p>
    `,

    "roomsDescription_en": `${NUMBER_ROOMS} rooms of different types in the Vatican area. \
    The rooms are bright, with own bathroom, free WI-WF, Air Conditionair, firdge, hair dryer and TV.
    Daily cleaning is guaranteed.
    <p>Reception in V. Catone 21 or on-demand from 9.00 to 24.00.</p>\
    <h3>Check-in Via Catone 21 and in via Leone IV 38: from 14.00 to 24.00  -  Available check-in after 24.00 with extra charge.</h3>\
    <h3>Check-out: before 10.30am</h3>`,

    "apartmentsDescription_en": `For families or groups who wish a pleasant and confortable stay in perfect locations, keeping the privacy which only an apartment can offer. Apartments are availble in order to accommodate up to ${MAX_NUMER_PEOPLE_APARTMENTS} people. Each apartment has free WIFI and kitchen.`,

    "contactsDescription_en": `
        <div>
            Remind the following advantages if you choose to book directly from the website or via email:
            <ol>
                <li>free breakfast</li>
                <li>free luggage deposit</li>
                <li>room upgrade, subject to availability</li>
            </ol>
        </div>

        <div>
            For further information you can:
            <ul>
                <li>send an email to info@whitevatican.com</li>
                <li>call (+39) 3426425792 or (+39) 335 7804080</li>
            </ul>
        </div>

        <p>
            For the direct reservation you can email to info@whitevatican.com
        </p>
        <p>
            We require a small deposit via bank transfer or credit card in order to verify the reservation
        </p>
        <p>
        Free cancellation with at least 7 days notice, otherwise a 30% fee will be applied.    
        </p>

        <h3>RECEPTION C/O WHITE VATICAN</h3>
        <h4>Left side, 3° floor, door 8</h4
    `,

    "reminder_booknowDescription_en": `
        If you book here:
        <ul>
            <li>free breakfast</li>
            <li>free luggage deposit before check-in and after checkout</li>
            <li>room upgrade, subject to availability</li>
        </ul>
    `,

    // Spanish text
    "ourofferLabel_es": "Nuestra oferta",
    "aboutLabel_es": "About",
    "roomsLabel_es": "Habitaciones",
    "apartmentsLabel_es": "Apartamentos",
    "whereLabel_es": "Donde estamos",
    "contactsLabel_es": "Contactarnos",
    "peopleLabel_es": "Personas",
    "configurationLabel_es": "Configuracion",
    "bathroomsLabel_es": "Banos",
    "kitchensLabel_es": "Cocinas",
    "booknowLabel_es": "Reserva ahora!",

    "unique_locationLabel_es": "Posición única",
    "receptionLabel_es": "Reception 9:00-24:00",
    "airportLabel_es": "Aeropuerto por solo €6,00",
    "easy_by_carLabel_es": "Fácil en coche",
    "discountLabel_es": "15% de descuento para clientes ya",
    "free_brochureLabel_es": "Brochure gratuita",

    "unique_locationDescription_es": `
        <p>300 mt de S.Peter iglesia and 150 mt de la metro Ottaviano</p>
        <p>Cerca de Piazza Navona, Fontana di Trevi and Trastevere</p>
    `,
    "receptionDescription_es": `
        <p>Check-in Via Catone 21 and in via Leone IV 38. suplemento despues de las 24:00.</p>
        <p>Check-out: not later than 10.30</p>
    `,
    "airportDescription_es": `
        <p> Bus cada 20 min </p>
        <p> Fiumicino - via Crescenzio </p>
        <p> Ciampino - Termini </p>
        <p> Bajo demanda, recogida privada.</p>        
    `,
    "easy_by_carDescription_es": `
        <p>No hay límite de acceso en coche. Aparcamiento seguro en la calle o Parking Prati.</p>  
    `,
    "discountDescription_es": `
        <p>Descuento para amigas y familiares tambien.</p>
    `,
    "free_brochureDescription_es": `
        <p>Te ayudamos con el alquiler de restaurantes, automóviles, motocicletas o bicicletas.</p>
    `,

    "contactsDescription_es": `
        <div>
            Recuerde las siguientes ventajas si elige reservar directamente desde el sitio web o por correo electrónico:
            <ol>
            <li>desayuno gratis</li>
            <li>depósito de equipaje gratuito</li>
            <li>Upgrade de habitación, sujeto a disponibilidad</li>
            </ol>
        </div>

        <div>
            Para más información puedes:
            <ul>
                <li>enviar un correo electronico a info@whitevatican.com</li>
                <li>llamar (+39) 3426425792 o (+39) 335 7804080</li>
            </ul>
        </div>

        <p>
        Para la reserva directa puede enviar un correo electrónico a info@whitevatican.com
        </p>
        <p>
            Requerimos un pequeño depósito mediante transferencia bancaria o tarjeta de crédito para verificar la reserva.
        </p>
        <p>
            Cancelación gratuita con al menos 7 días de anticipación, de lo contrario se aplicará una tarifa del 30%.  
        </p>

        <h3>RECEPTION C/O WHITE VATICAN</h3>
        <h4>Lado izquierdo, piso 3°, puerta 8</h4
    `,

    "reminder_booknowDescription_es": `
        Si reserva aqui:
        <ul>
            <li>desayuno gratis</li>
            <li>depósito de equipaje gratuito antes del check-in y después del check-out</li>
            <li>Upgrade de habitación, sujeto a disponibilidad</li>
        </ul>
    `,
    
    
    // German text
   

    // French text
   
    // Brasilian text   
 
    // Russian text
   
    // Japanese text
   
    // Chinese text
   
    // Arabic text
   
    // Polish text
   
}