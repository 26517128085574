import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { HttpClient,HttpErrorResponse} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { baseUrlMarket } from '../constants';
import { Config, ConfigAPI } from './models/config';



@Injectable() 
export class ConfigService {
    private _entity: BehaviorSubject<Config>; 
    
    // this object is observed in the other components
    config: Observable<Config>

    private baseUrl: string;
    private dataStore: {  // This is where we will store our data in memory
      config: Config
    };
      
  
    constructor(private httpService: HttpClient, private http: Http) {
        this.baseUrl  = baseUrlMarket;
        this.dataStore = { config: undefined};
        this._entity= <BehaviorSubject<Config>>new BehaviorSubject({});
        this.config = this._entity.asObservable();
    }


    /**
     * GET the config of the market app.
     * 
     */
    getConfig(lega: string){
        this.httpService.get( this.baseUrl + `getConfig.php?lega=${lega}`,  {}  ).subscribe(data => {
            let result = data as ConfigAPI;
            
            this.dataStore.config = {
              lega: result["lega"], timerSeconds: result["timerSeconds"], users: result["users"].split(" "),
              totalBudget: result["totalBudget"], budgetFebruary: result["budgetFebruary"], totalPlayers: result["totalPlayers"] 
            };
            
             this._entity.next(Object.assign({}, this.dataStore.config));      
          }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured.");
            } else {
              console.log("Server-side error occured: " + err.message);
            }
          })
    }

    /**
     * POST the timer value and store in the DB.
     * Only the leader can set the timer value.
     * @param seconds 
     * 
     */
    setTimerValue(seconds:number, player:string){
        const httpOptions = {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            }
        };
        
        this.httpService.post(this.baseUrl + "insertTimerPlayer.php", {
        "value":  seconds, "player": player }, httpOptions).subscribe(data => {
                let result = data as string;
                if (result["result"] == "OK"){
                    console.log("timer and player updated")
                }
                else{
                    alert("ERROR OCCURRED with the timer")
                }
        }, error => console.log('Could not update the timer.'));

    }
}
