
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { LanguageActions } from '../../language/language.actions';
import { LANGUAGES } from '../constants';
import { Language }  from '../domain/language.model';
import { Observable } from 'rxjs/Observable';




@Component({
  selector: 'my-where-to-go',
  templateUrl: './where-to-go.component.html',
  styleUrls: ['where-to-go.component.css'],

})

export class WhereToGoComponent{
    language:Language;
    language$:Observable<Language>;


    constructor(private route: ActivatedRoute, private router: Router){
    
    this.route.params.subscribe(params => {
        var value = params['lang'];
        
        if(!LANGUAGES.includes(value)){
            this.router.navigate(['*']);
        }
        else{
          this.language = {id:LANGUAGES.indexOf(value),value:value};
        }
        //this.store.dispatch(this.languageActions.editLanguage(this.language));
     });
    
    }

}