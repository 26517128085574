import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { APP_IMPORTS } from './app.imports';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { NotFound404Component } from './notfound404.component';
import { HomeComponent } from './home/home.component';
import { WhereComponent } from './where/where.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { ServicesComponent } from './services/services.component';
import { ContactsComponent } from './contacts/contacts.component';
import { WhereToGoComponent } from './where-to-go/where-to-go.component';

import { WindowRef} from './WindowRef';


import { MarketComponent } from './market-session/market.component';
import { CountdownTimerComponent } from './market-session/countdown-timer.component';
import { HttpClientModule } from '@angular/common/http';

import { UserService } from './market-session/user_service';
import { TimerService } from './market-session/timer_service';
import { ConfigService } from './market-session/config_service';

import {CdkTableModule} from '@angular/cdk/table';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatInput,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';

import { TeamsTableComponent } from './market-session/teams-table/teams-table.component';
import { PresidentSelectionComponent } from './market-session/president-selection/president-selection.component';
import { PlayersAutocompleteComponent } from './market-session/players-autocomplete/players-autocomplete.component';

@NgModule({
  exports: [
    CdkTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  // declarations: [PlayersAutocompleteComponent]
})
export class DemoMaterialModule {}


@NgModule({
  declarations: [
    AppComponent,
    NotFound404Component,
    HomeComponent,
    WhereComponent,
    RoomsComponent,
    ApartmentsComponent,
    ServicesComponent,
    ContactsComponent,
    WhereToGoComponent,
  
    //Comment this module out
    MarketComponent,
    CountdownTimerComponent,
    TeamsTableComponent,  
    PresidentSelectionComponent,
    PlayersAutocompleteComponent
  ],
  imports: [
    APP_IMPORTS,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCPRJqOQVDl4cljBBXyxlBLS4cVJbKPC44'
    }),
    DemoMaterialModule

  ],
  
  // this is necessary in order to import custom tags and components
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],

  providers: [WindowRef, UserService, TimerService, ConfigService],
  bootstrap: [AppComponent]
})


export class AppModule {}
