
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NUMBER_PHOTOS_LUXURY, NUMBER_PHOTOS_STANDARD, NUMBER_PHOTOS_ECONOMY, NUMBER_ROOMS } from '../constants';

import { text } from '../text';

@Component({
  selector: 'my-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['rooms.component.css'],

})

export class RoomsComponent {
    
    number_rooms:number = NUMBER_ROOMS;


    path_photos_luxury:string = '/assets/themes/dr/images/rooms_luxury/';
    photo_rooms_luxury:string[] = [];

    path_photos_standard:string = '/assets/themes/dr/images/rooms_standard/';
    photo_rooms_standard:string[] = [];

    path_photos_economy:string = '/assets/themes/dr/images/rooms_economy/';
    photo_rooms_economy:string[] = [];


    @Input() language: string
    photo_sliders:string[] = [];

    
    constructor(private route: ActivatedRoute, private router: Router){
        
        for(let i=0; i<NUMBER_PHOTOS_LUXURY; i++){
            this.photo_rooms_luxury.push(this.path_photos_luxury + i + ".jpg")
        }

        for(let i=0; i<NUMBER_PHOTOS_STANDARD; i++){
            this.photo_rooms_standard.push(this.path_photos_standard + i + ".jpg")
        }

        for(let i=0; i<NUMBER_PHOTOS_ECONOMY; i++){
            this.photo_rooms_economy.push(this.path_photos_economy + i + ".jpg")
        }
    }


    getLabel(label: string, language: string) {
        return text[label+'Label_'+language]
    }

    getDescription(label: string, language: string) {
        return text[label+'Description_'+language]
    }
}