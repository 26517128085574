import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';

import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';

import playersJSON from '../../../assets/players2024-2025.json';


export interface Player {
  id: number;
  name: string;
  base: number;
}

@Component({
  selector: 'app-players-autocomplete',
  templateUrl: './players-autocomplete.component.html',
  styleUrls: ['./players-autocomplete.component.css']
})
export class PlayersAutocompleteComponent implements OnInit {

  @ViewChild('player') value: ElementRef;
  player: string;

  control = new FormControl('');
  players: Player[] = []
  filteredPlayers: Observable<Player[]>;

  constructor() { }

  ngOnInit() {
    for (let i = 0; i < playersJSON.length; i++) {
      this.players.push({id: playersJSON[i].Id, name: playersJSON[i].Nome, base: playersJSON[i]['Qt.A']})
  }
    this.filteredPlayers = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): Player[] {
    const filterValue = this._normalizeValue(value);
    return this.players.filter(player => this._normalizeValue(player.name).includes(filterValue));
  }
  
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  getPlayer() {
    const selectedPlayers = this._filter(this.value.nativeElement.value)
    if (selectedPlayers.length !== 1) {
      alert("Not a single player selected!")
    }
    return {player: this.value.nativeElement.value || undefined, fantaId: selectedPlayers[0].id, baseCost: selectedPlayers[0].base}
  }
  setPlayer(newValue:string) {
    return this.value.nativeElement.value = newValue
  }
}
