
import { Component, Input, Renderer2, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormControl} from '@angular/forms';
import { text } from '../text'

import { NUMBER_PHOTOS_SLIDERS, NUMBER_ROOMS } from '../constants';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'my-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.css'],
})

export class HomeComponent{
    
  @Input() language:string;
    
  path_photos:string = '/assets/themes/dr/images/sliders/';
  photo_sliders:string[] = [];
  number_rooms:number = NUMBER_ROOMS;

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  constructor(private route: ActivatedRoute, private router: Router){
      
      // get the language from the props

      for(let i=0; i<NUMBER_PHOTOS_SLIDERS; i++){
          this.photo_sliders.push(this.path_photos + i + ".jpg")
      }
  }

  // public ngOnInit() {}


  getLabel(label: string, language: string) {
      return text[label+'Label_'+language]
  }
  getDescription(label: string, language: string) {
    return text[label+'Description_'+language]
  }

}