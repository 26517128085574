import { Component, OnInit, Input } from '@angular/core';
import { President } from '../models/president';
import { UserService } from '../user_service';

@Component({
  selector: 'app-president-selection',
  templateUrl: './president-selection.component.html',
  styleUrls: ['./president-selection.component.css']
})
export class PresidentSelectionComponent implements OnInit {

  // comes from the main market component after the user is logged into the application
  @Input() presidents:President[];
  @Input() currentPresident: President;

  // the president logged into the application
  loggedPresident: President;

  constructor() { }

  ngOnInit() {

    //This is called only when the component is rendered, i.e. when the user is logged in
    console.log("currentPresident", this.currentPresident)
  }

}
