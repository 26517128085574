import { Component, OnDestroy, OnInit } from '@angular/core';
import { TimerService } from './timer_service';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from './config_service';

 
@Component({
  selector: 'app-countdown-timer',
  template: '<div class="seconds"></div>'
})
export class CountdownTimerComponent implements OnInit, OnDestroy {

  constructor (private timerService:TimerService, private configService: ConfigService) {}
  
  intervalIdOfferSeconds = 1
  intervalId = 0;
  configTimerSeconds = undefined;
  secondsLeader = undefined;
  seconds:number;

  // boolean to disable the possibility to offer.
  // When boolean is True the user can see the offers
  offerDisabled:boolean=true


  clearTimerOfferSeconds() { clearInterval(this.intervalIdOfferSeconds); }
  clearTimer() { clearInterval(this.intervalId); }
 
  ngOnInit() {

    // subscribe to config changes
    this.configService.config.subscribe((config) => {
        this.configTimerSeconds = config.timerSeconds
        this.secondsLeader = config.timerSeconds
    });
  }

  /**
   * method to check the timer value every second.
   * It is used by all the users that are not leader
   */
  checkTimeOffer(){
    this.intervalIdOfferSeconds = window.setInterval(() => {
      this.timerService.getTimerValue()
      }, 1000);
  
  }
  
  ngOnDestroy() { this.clearTimer(); }
  
  resetTime(){
      this.timerService.setTimerValue(this.configTimerSeconds, undefined)
  }

  // Method called only by the leader
  start(player: string) { this.countDown(player); }


  // Only the leader can press start and consequently this method is called only by the leader
  // this method triggers the timerService, that will update its value and will unblock
  // all the other users.
  private countDown(player: string) {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      if (this.secondsLeader === 0) {

        // disable the possibility to offer for the leader
        this.offerDisabled=true
        this.clearTimer();
      } else if (this.secondsLeader > 0) {
          this.secondsLeader -= 1;

          // enable the possibility to offer for the leader
          this.offerDisabled=false
          this.timerService.setTimerValue(this.secondsLeader, player);
      }
    }, 1000);
  }
}