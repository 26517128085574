
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { LanguageActions } from '../../language/language.actions';
import { LANGUAGES } from '../constants';
import { Language }  from '../domain/language.model';
import { Observable } from 'rxjs/Observable';




@Component({
  selector: 'my-services',
  templateUrl: './services.component.html',
  styleUrls: ['services.component.css'],

})

export class ServicesComponent{
    language:Language;
    language$:Observable<Language>;

    startReception:string = "10:00"
    endReception:string = "24:00"
    costoCenaVaticano:number = 25
    costoCenaPesce: number = 25


    constructor(private route: ActivatedRoute, private router: Router){
    
    // this.route.params.subscribe(params => {
    //     var value = params['lang'];
        
    //     if(!LANGUAGES.includes(value)){
    //         this.router.navigate(['*']);
    //     }
    //     else{
    //       this.language = {id:LANGUAGES.indexOf(value),value:value};
    //     }

    //     console.log("language services: " + this.language.value);
    //     //this.store.dispatch(this.languageActions.editLanguage(this.language));
    //  });
    
    }

}