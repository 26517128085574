import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import { total_users, TOTAL_NUMBER_PLAYERS, LEGA } from 'app/constants';
import { Market } from '../models/market';
import { ConfigService } from '../config_service';
import { UserService } from '../user_service';

import * as _ from "lodash";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-teams-table',
  templateUrl: './teams-table.component.html',
  styleUrls: ['./teams-table.component.css']
})
export class TeamsTableComponent implements OnInit {

  displayedColumns = ['position', 'name', 'weight', 'symbol'];
  presidents = total_users;
  presidentsBudget = {}
  
  ROLE_COLOR_MAPPING = {
    "P": "orange",
    "D": "blue",
    "C": "green",
    "A": "red"
  }


  // this will be an array of X entries (the number of players)
  teams = []
  teamsToExport = []
  totalBudget = undefined
  marketEntries: Market[] = [];
  dataSource: MatTableDataSource<Object>;

  constructor(private userService:UserService, private configService: ConfigService) { }

  ngOnInit() {
    this.configService.config.subscribe((config) => {
        this.totalBudget = config.totalBudget
    });


    this.userService.marketEntries.subscribe(
      (entries) => {
          // reset to initial values the budgets
          this.presidentsBudget = {
            lucagiacomelli: this.totalBudget,
            matteogiacomelli: this.totalBudget,
            alessandrobosco: this.totalBudget,
            marcobosco: this.totalBudget, 
            danielecaraffa: this.totalBudget, 
            giuseppecaraffa: this.totalBudget, 
            riccardodesantis: this.totalBudget, 
            simonefrasacco: this.totalBudget, 
            federicocoletti: this.totalBudget, 
            antoniomarzano: this.totalBudget
          }
          if (entries.length > 0) {
              this.marketEntries = []
              for (let entry of entries) {
                  this.marketEntries.push(entry)
              }
          }
          this.teams = this.buildTeams(this.marketEntries)
          this.dataSource = new MatTableDataSource(this.teams);
      }
    )
    this.populateTeams()
      
  }

  populateTeams() {
    this.userService.getAllMarketEntries(new HttpParams().set("lega", LEGA))
  }

  exportTeams() {
    const csvContentList = []
    let index = 0
    for (const username of Object.keys(this.teamsToExport)) {
      csvContentList.push('$,$,$')
      const players = this.teamsToExport[username]
      for (let player of players) {
        csvContentList.push(`${username},${player.fantaId},${player.cost}`)
      }
      index = index + 1
    }

    const csvContent = csvContentList.join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading the file
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute('download', 'exportTeams.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  

  buildTeams(inputTeams: Object[]) {
    var placeholders = [];
    for(var user of this.presidents) {
      this.teamsToExport[user] = []
    }

    for(var i:number=0; i<TOTAL_NUMBER_PLAYERS; i++) {
      const row = {}
      for(var user of this.presidents) {
        row[user] = {}
      }
      placeholders.push(row)
    }
 
    for (var obj of inputTeams) {
      for (var placeholder of placeholders) {
        if (Object.keys(placeholder[obj["username"]]).length === 0 && placeholder[obj["username"]].constructor === Object) {
          placeholder[obj["username"]] = {
            player: obj["player"], 
            cost: obj["cost"],
            baseCost: obj["baseCost"],
            role: obj["role"],
            color: this.ROLE_COLOR_MAPPING[obj["role"]] 
          }
          this.presidentsBudget[obj["username"]] -= obj["cost"]
          this.teamsToExport[obj["username"]].push({fantaId: obj["fantaId"], cost: obj["cost"]})
          break
        }
      }
    }
    // filter out the empty columns
    placeholders = placeholders.filter(obj => !_.isEqual(obj, {lucagiacomelli: {}, matteogiacomelli: {}, alessandrobosco: {}, marcobosco: {}, danielecaraffa: {}, giuseppecaraffa: {}, riccardodesantis: {}, federicocoletti: {}, simonefrasacco: {}, antoniomarzano: {}}));
    return placeholders;
  }
}
