
import { Component, AfterViewInit, ViewChild, OnDestroy, OnInit, HostListener, ElementRef } from '@angular/core';
import { CountdownTimerComponent }  from './countdown-timer.component';

import {  Http } from '@angular/http';
import { Observable } from 'rxjs';

import {UserService , Offer} from './user_service'
import { LEGA, LEADER_MARKET, baseUrlImage } from '../constants';
import { President } from './models/president';
import { TeamsTableComponent } from './teams-table/teams-table.component';
import { TimerService } from './timer_service';
import { ConfigService } from './config_service';
import { Market } from './models/market';
import { HttpParams } from '@angular/common/http';
import { PlayersAutocompleteComponent } from './players-autocomplete/players-autocomplete.component';

@Component({
  selector: 'market-session',
  templateUrl: './market.component.html',
  styleUrls: ['market.component.css'],
  host: {'window:beforeunload':'quit'}
})
export class MarketComponent implements AfterViewInit, OnInit, OnDestroy{
    public offers$: Observable<Offer[]>;

    lega: string = LEGA
    configSecondsTimer: number = undefined;
    total_users: string[] = []
    
    offerObjects: Object[] = []
    winningOffers: Object[] = []

    leader:string = LEADER_MARKET
    
    name_user_logged:string;
    image_profile:string;
    
    loggedUsers: string[];

    presidents: President[];

    // the president logged into the application
    loggedPresident: President;

    currentRole: string = ''
    currentPlayer: string = ''
    currentPlayerFantaId: string = ''
    currentBaseCost: number = 0
    historyCurrentPlayer: Market[] = []

    // the president who proposed the currentPlayer
    currentPresident: President

    // boolean to disable the possibility to offer.
    // When boolean is True the user can see the offers
    offerDisabled:boolean=true

    @ViewChild(CountdownTimerComponent) timerComponent: CountdownTimerComponent;
    @ViewChild(TeamsTableComponent) teamsTableComponent: TeamsTableComponent;
    @ViewChild(PlayersAutocompleteComponent) playersAutocompleteComponent: PlayersAutocompleteComponent;
    @ViewChild('role') rolePlayer: ElementRef;
    // @ViewChild('player') inputPlayer: ElementRef;
    @ViewChild('baseCost') inputBaseCost: ElementRef;
    @ViewChild('offer') inputOffer: ElementRef;
    

    constructor (private userService:UserService, private timerService: TimerService, private configService: ConfigService) {}
   
    seconds() { return 0; }

    ngOnInit(){

        // subscribe to config changes
        this.configService.config.subscribe((config) => {
            this.configSecondsTimer = config.timerSeconds
            this.total_users = config.users
        });

        

        // this is where the timer is updated and the button to offer is enabled/disabled
        // for the users that are not leader.
        // the idea is to listen for any change to the timer value.
        // when the user is logged in, they start to listen every second.
        this.timerService.timerPlayer.subscribe(timerPlayer => {
            this.timerComponent.seconds = timerPlayer.timer
            if (this.timerComponent.seconds == 0){
                this.timerComponent.offerDisabled  = true
            }
            else if (this.timerComponent.seconds < this.configSecondsTimer){

                // TODO: populate the current Player for redering and
                // for having it ready when submitting the offer

                if (this.timerComponent.offerDisabled) {
                    this.timerComponent.offerDisabled = false
                    this.offerObjects = []
                    this.winningOffers = []

                    this.userService.getPresidents()
                }

                // for non leader users, get the history if the current player is new
                if (this.name_user_logged != this.leader) {
                    if (timerPlayer.player !== this.currentPlayer) {
                        this.userService.getHistoryPlayer(new HttpParams().set("player", timerPlayer.player.toLowerCase()))
                    }
                    this.currentPlayer = timerPlayer.player
                }
            }
        });

        // subscribe to config changes
        this.userService.historyPlayer.subscribe((historyPlayer) => {
            this.historyCurrentPlayer = historyPlayer
        });

        // subscribe to active users changes
        this.userService.users.subscribe(users => {
            this.loggedUsers = users;

            if (this.loggedUsers.indexOf(this.name_user_logged) > -1) {

                // if the login is successful, we get the presidents and
                // populate the current and logged President objects
                this.userService.getPresidents()
                
                // start polling for users that are not the leader.
                // the polling allows to update the status of the application for those users
                if (this.name_user_logged != this.leader)
                    this.timerComponent.checkTimeOffer()
                else
                    // initially we set the timer to the defulat number of seconds
                    this.timerComponent.resetTime()
            }
        });

        // subscribe to president details and status changes
        this.userService.presidents.subscribe(presidents => {
            
            // the result of the query is ordered by position
            this.presidents = presidents
            for (var president of presidents) {
                if (president["username"] === this.name_user_logged) {
                    this.loggedPresident = president
                }
                if(president["current"] == true){
                    this.currentPresident = president
                }
            }
            this.image_profile = baseUrlImage + this.name_user_logged + ".png"
        })
    
        // subscribe to offers changes
        this.userService.offers.subscribe(
            (offers) => {
                this.offerObjects = []
                this.winningOffers = []
                if (offers.length > 0) {
                    for (let offer of offers) {
                        this.offerObjects.push({user: offer.user, value: offer.value, winning: false})
                    }
                    var firstValue = this.offerObjects[0]["value"]
                    for (let offerObject of this.offerObjects) {
                        if (offerObject["value"] === firstValue) {
                            offerObject["winning"] = true
                            this.winningOffers.push(offerObject)
                        }
                    }

                    // if the currentPresident is in the winningOffers, they win
                    for (let winningOffer of this.winningOffers) {
                        if (winningOffer["user"] === this.currentPresident["username"]) {
                            this.winningOffers = [winningOffer]
                            break
                        }
                    }

                    // TODO: the winning offer value has to be more than the baseCost
                }
            }
        )

        this.configService.getConfig(this.lega)
    }
   
    ngAfterViewInit() {
      // Redefine `seconds()` to get from the `CountdownTimerComponent.seconds` ...
      // but wait a tick first to avoid one-time devMode
      // unidirectional-data-flow-violation error
      setTimeout(() => this.seconds = () => this.timerComponent.secondsLeader, 0);
    }

    ngOnDestroy(){}

    @HostListener('window:beforeunload')
    quit() {
        this.logout(this.name_user_logged);
    }

    login(name:string){

        // Load the active users into the system
        this.userService.loadUsers()

        if (this.loggedUsers != undefined){

            // check if the name is present in the list of possible users
            if (this.total_users.indexOf(name) > -1){
                
                // check if the name is already logged
                if (this.loggedUsers.indexOf(name) > -1){
                    alert("user already logged!")
                }
                else{                    
                    this.userService.create(name);
                    this.name_user_logged = name          
                }
            }
            else {
                alert("Immetti nome e cognome tutto minuscolo e senza spazi!")
            }
        }
    }

    logout(name:string){
        this.userService.remove(name)
        this.loggedPresident = undefined
        this.timerComponent.clearTimerOfferSeconds()
    }

    submitOffer(){
        let offer = parseInt(this.inputOffer.nativeElement.value)        
        this.userService.makeOffer(this.name_user_logged, "", offer)
        this.inputOffer.nativeElement.value = ""
        this.offers$ = undefined;
    }

    getOffers(){
        this.inputOffer.nativeElement.value = ""
        this.userService.getOffers();
    }


    // Method called only by the leader
    start() {
        if (this.currentPresident == undefined) {
            alert("Mischia i presidenti per iniziare con il primo giocatore!")
            return
        }
        
        this.currentRole = this.rolePlayer.nativeElement.value
        this.currentPlayer = this.playersAutocompleteComponent.getPlayer().player
        this.currentPlayerFantaId = `${this.playersAutocompleteComponent.getPlayer().fantaId}`
        this.inputBaseCost.nativeElement.value = this.playersAutocompleteComponent.getPlayer().baseCost

        this.currentBaseCost = this.inputBaseCost.nativeElement.value
        if (this.currentPlayer == undefined || this.currentPlayer === "" || this.currentRole == undefined || this.currentRole === "") {
            alert("please enter role and player!")
            return
        }
        this.inputOffer.nativeElement.value = ""
        this.timerComponent.start(this.currentPlayer); 
        this.timerComponent.offerDisabled = false
        this.userService.getHistoryPlayer(new HttpParams().set("player", this.currentPlayer.toLowerCase()))
    }

    updateNextCurrentPresident() {
        for (let i = 0; i < this.presidents.length; i++) {
            if (this.presidents[i]["username"] === this.currentPresident["username"]) {
                
                // we update the currentPresident rotating in the array of presidents
                this.currentPresident["current"] = false
                this.currentPresident = this.presidents[(i+1)%this.presidents.length]
                this.currentPresident["current"] = true
                break;
            }
        }
    }

    // Method called only by the leader.
    // This method reset the offers and the other players cannot see them.
    // Once this method is called we move forward to the next player and we also move the currentPresident
    assignToUser(user:string, cost:number) {
        if (this.currentPlayer == '') {
            alert("Giocatore già assegnato al presidente")
            return
        }

        this.userService.addPlayerToUser(user, this.currentPlayer, this.currentRole, cost,  this.currentBaseCost, this.currentPlayerFantaId)

        this.playersAutocompleteComponent.setPlayer("")
        this.inputBaseCost.nativeElement.value = 0

        this.timerComponent.secondsLeader = this.configSecondsTimer;

        // update the list of presidents before updating the DB
        this.updateNextCurrentPresident()

        for (let president of this.presidents){
            this.userService.makeOffer(president["username"], "", 0)
            this.userService.updatePresident(president)
        }
        this.offerObjects = []
        this.winningOffers = []

        // NOTE: don't take into account network failure. 
        // The user service call is asynchrounous
        this.currentPlayer = ''
        this.currentBaseCost = 0
        this.historyCurrentPlayer = []
    }

    repeatOffer() { 
        this.timerComponent.secondsLeader = this.configSecondsTimer;
        for (let president of this.presidents){
            this.userService.makeOffer(president["username"], "", 0)
        }
        this.offerObjects = []
        this.winningOffers = []
    }

    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
      }


    shufflePresidents() {
        this.presidents = this.shuffle(this.presidents)
        let currentIndex = Math.floor(Math.random() * this.presidents.length)
        
        for (let i = 0; i < this.presidents.length; i++) {
            this.presidents[i]["positionInAuction"] = i+1
            this.presidents[i]["current"] = i == currentIndex

            this.userService.updatePresident(this.presidents[i])
        }
        this.currentPresident = this.presidents[currentIndex]
    }

    skipPresident() {
        this.updateNextCurrentPresident()
        for (let president of this.presidents){
            this.userService.updatePresident(president)
        }
    } 
}
